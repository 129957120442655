import React from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import MainContainer from '../../container/main'
import SEO from '../../components/seo'

import Container from '../../components/container'
import ContentHeroNew from '../../components/content-hero-new'
import ContentInlineNav from '../../components/content-inline-nav'
import ContentBlockLarge from '../../components/content-block-large'
import ContentBlockSmall from '../../components/content-block-small'
import ContentBannerWithCTA from '../../components/content-banner-with-cta'

// import IconManageTasksAcrossAllApps from '../../assets/feature-icons/manage-tasks-across-all-apps.png'
// import IconSimplifyDailyRoutines from '../../assets/feature-icons/simplify-daily-routines.png'
// import IconCreateTasksEverywhere from '../../assets/feature-icons/create-tasks-everywhere.png'
// import IconAssignTasksToTeammates from '../../assets/feature-icons/assign-tasks-to-teammates.png'
// import IconSeeEverthingYouNeedToDoToday from '../../assets/feature-icons/see-everything-you-need-to-do-today.png'
// import IconMultiDevice from '../../assets/feature-icons/multi-device.png'

// import IconOneTaskSolution from '../../assets/feature-icons/one-task-solution.png'
// import IconSimplifyRoutinesForEmployees from '../../assets/feature-icons/simplify-routines-for-employees.png'
// import IconOptimizeOperationalTasks from '../../assets/feature-icons/optimize-operational-tasks.png'
// import IconWorkWithDataCrossApp from '../../assets/feature-icons/work-with-data-cross-app.png'
// import IconGetWorkloadInsights from '../../assets/feature-icons/get-workload-insights.png'
// import IconManageAllProjectsInOneSolution from '../../assets/feature-icons/manage-all-projects-in-one-solution.png'

import IconUsers from '../../assets/images/ic_users.svg'

const IndexPage = ({ data }) => (
  <MainContainer>
    <SEO title="Basaas Tasks" />

    <Container>
      <ContentHeroNew
        kicker="One central solution for all tasks"
        headline="Increase productivity with a cross-app task management"
        actionLinkText="Get started – It's free!"
        actionLinkIsExternal
        actionLink="https://workplace.basaas.com/register/?utm_source=basaas-com&utm_medium=hero&utm_campaign=signup&utm_content=home"
        media={<StaticImage src="../../assets/images/tasks-hero.png" alt="Optimize your app management" placeholder="blurred" />}
      />
    </Container>
    <Container style={{ zIndex: '2' }}>
      <ContentInlineNav
        kicker="Managing a team or company?"
        headline="Learn how Basaas Tasks can help your users to boost their teamwork"
        // media={<IconTrust />}
        secondary={
          <div className="item">
            <a className="bas__platform-btn bas__platform-download-app-btn" data-platform="win" href="#tasks-for-teams">
              <IconUsers className="icon" />
              All benefits for teams
            </a>
          </div>
        }
      />
    </Container>
    {/* Block Workplace USER */}
    <Container withBackground style={{ padding: '80px 0' }}>
      <ContentBlockLarge
        kicker="Get more done"
        headline="Unite all tasks of your favorite apps in one place"
        description="Connect Asana, Jira, MeisterTask and more to a unified task solution. Integrate your favorite apps and manage your entire daily business in a single place."
        // actionLinkText="Learn more"
        // actionLink="/workplace"
        media={<StaticImage src="../../assets/images/tasks-user.png" alt="Unite all tasks of your favorite apps in one place" placeholder="blurred" />}
      />
      <Container columns={2} style={{ marginTop: '-48px' }}>
        <ContentBlockSmall
          headline="Manage your tasks across all apps"
          description="Connect your tasks tools and bring all tasks and reminders into one central and well-organized solution. View and edit tasks of your task tools you are already using in one place. Get cross app overview of your workflows and be more productive."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../../assets/feature-icons/manage-tasks-across-all-apps.png" alt="Manage your tasks across all apps" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="Simplify your daily routines"
          description="Basaas Tasks offers everything you need to keep track of all your daily tasks. Get notified when your tasks are due so you will never miss a deadline anymore."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../../assets/feature-icons/simplify-daily-routines.png" alt="Simplify your daily routines" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="Create tasks everywhere"
          description="Create tasks anywhere in any browser solution and save them directly to your favorite app. Sometimes, for example, you get an email that triggers a new to-do. Just right-click in your email app and create a task in Basaas or directly in your favorite task management."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../../assets/feature-icons/create-tasks-everywhere.png" alt="Create tasks everywhere" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="Assign tasks to teammates"
          description="Share your workload and assign tasks to responsible teammates. You can simply delegate tasks to colleagues. Keep always the overview of your delegated tasks and get notified about completed tasks."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../../assets/feature-icons/assign-tasks-to-teammates.png" alt="Assign tasks to teammates" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="See everything you need to do today"
          description="Stay perfectly organized and focus on today‘s most important tasks. Basaas offers you a perfect overview of today’s agenda so you can prioritize your workday and focus your energy in the right direction."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../../assets/feature-icons/see-everything-you-need-to-do-today.png" alt="See everything you need to do today" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="Work anywhere and on any device"
          description="Basaas lets you work from anywhere and on any device. The only precondition is an internet connection and a browser. Responsive design makes Basaas perfect for flexible use by smartphones and tablets. With Basaas you are perfectly prepared for remote work."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../../assets/feature-icons/multi-device.png" alt="Work anywhere and on any device" placeholder="blurred" />}
        />
      </Container>
    </Container>
    {/* Block Workplace MANAGER */}
    <Container withBackground style={{ padding: '80px 0' }} id="tasks-for-teams">
      <ContentBlockLarge
        kicker="Always keep the overview"
        headline="Organize tasks across apps and beyond teams"
        description="Integrate all leading task and project management solutions and your productivity and collaboration tools to one single place for all enterprise tasks."
        // actionLinkText="Learn more"
        // actionLink="/workplace"
        media={<StaticImage src="../../assets/images/tasks-manager.png" alt="Optimize your app management" placeholder="blurred" />}
        reverse
      />
      <Container columns={2} style={{ marginTop: '-48px' }}>
        <ContentBlockSmall
          headline="One solution for all enterprise tasks"
          description="Depending on the needs of different departments, you probably already use different task and project management solutions. Basaas integrates leading apps and combines all tasks and reminders into one central and well-organized solution."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../../assets/feature-icons/one-task-solution.png" alt="One solution for all enterprise tasks" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="Simplify daily routines for all employees"
          description="Basaas Tasks makes working in different teams and across apps much easier. Every user can create tasks in any app and manage all tasks across apps in one place. Employees can collaborate perfectly and easily manage their daily agenda."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../../assets/feature-icons/simplify-routines-for-employees.png" alt="Simplify daily routines for all employees" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="Easily organize daily operative tasks"
          description="In every company, there is a myriad of operational business tasks like the quarterly sales forecast etc. With Basaas you can easily streamline or automate these operational tasks with team tasks or tasks for several team members at once."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../../assets/feature-icons/optimize-operational-tasks.png" alt="Easily organize daily operative tasks" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="Organize your remote working teams"
          description="Remote working has never been more popular. One challenge is to manage all colleagues working remotely while keeping productivity high. Basaas provides the basis for successful remote working, because every colleague has always a perfect overview of all relevant tasks."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../../assets/feature-icons/work-across-apps-teams.png" alt="Organize your remote working teams" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="Get improved workload insights"
          description="Effective task management requires managing all teammates. See all tasks of your colleagues and get an overview of the workload and efficiency. Basaas provides the whole picture of your team or your entire organization at a glance."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../../assets/feature-icons/get-workload-insights.png" alt="Get improved workload insights" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="Manage all your projects in one solution"
          description="The ability to manage projects is one of the most crucial skills for any organization. With Basaas Tasks, you can easily create projects to bring your team’s work together. Projects helps you to gain transparency to your team while improving collaboration."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../../assets/feature-icons/manage-all-projects-in-one-solution.png" alt="Manage all your projects in one solution" placeholder="blurred" />}
        />
      </Container>
    </Container>

    <Container style={{ padding: '80px 0' }}>
      <ContentBannerWithCTA />
    </Container>
  </MainContainer>
)

export default IndexPage
